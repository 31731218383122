import { FC, useState } from 'react';
import classnames from 'classnames';

import { urlParser } from '@hh.ru/browser-api-utils';
import { Card, Text } from '@hh.ru/magritte-ui';

import CdnImg from 'src/components/CdnImg';
import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';
import { PhotoUrl } from 'src/models/resume/resumeCommon.types';
import ResumePortfolioModal from 'src/pages/ResumeView/employerRedesign/components/ResumePortfolio/ResumePortfolioModal';

import styles from './styles.less';

const ResumePortfolioItem: FC<PhotoUrl> = ({ title, preview, big }) => {
    const imageResizingCdnHost = useSelector((state) => state.config.imageResizingCdnHost);

    const [modalVisibility, showModal, hideModal] = useOnOffState(false);
    const [error, setError] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);

    if (!preview || !big) {
        return null;
    }

    const bigImageSrc = urlParser(imageResizingCdnHost + big);

    const openModalImage = () => {
        showModal();

        const image = new Image();
        image.src = bigImageSrc.href;
        image.onload = () => setImageLoaded(true);
        image.onerror = () => setError(true);
    };

    return (
        <>
            <Card onClick={openModalImage} borderWidth="default" borderRadius={24}>
                <div className={styles.cardWrapper}>
                    <Card
                        stretched
                        verticalStretched={!title}
                        style="secondary"
                        borderWidth="default"
                        borderRadius={12}
                    >
                        <div
                            className={classnames(styles.imageWrapper, {
                                [styles.imageWrapperVerticalStretched]: !title,
                            })}
                        >
                            <CdnImg loading="lazy" path={big} alt="" className={styles.image} />
                        </div>
                    </Card>
                    {title && (
                        <Text typography="label-4-regular" maxLines={2}>
                            <AsyncHighlighter>{title}</AsyncHighlighter>
                        </Text>
                    )}
                </div>
            </Card>
            <ResumePortfolioModal
                visible={modalVisibility}
                onClose={hideModal}
                title={title}
                image={big}
                loaded={imageLoaded}
                error={error}
            />
        </>
    );
};

export default ResumePortfolioItem;
