import { useRef } from 'react';
import { connect } from 'react-redux';

import resumeLetterButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_letter_button_click';
import { EnvelopeOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import Button from 'bloko/blocks/button';
import HoverTip, { TipLayer } from 'bloko/blocks/drop/Tip/HoverTip';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import translation from 'src/components/translation';
import useIsClient from 'src/hooks/useIsClient';
import { TransitionType } from 'src/models/employerNegotiations/negotiationLinks';
import { UserType } from 'src/models/userType';
import { VIEW_WITHOUT_CONTACTS } from 'src/utils/constants/resumePermission';

const ResumeButtonSendMessage = ({ userType, isEmailEmpty, resumeId, vacancyId, link, permission, trls }) => {
    const isClient = useIsClient();
    const activatorRef = useRef(null);

    if (userType !== UserType.Employer || !link || permission === VIEW_WITHOUT_CONTACTS || isEmailEmpty) {
        return null;
    }

    return (
        <HoverTip
            host={isClient ? document.body : null}
            layer={TipLayer.Overlay}
            render={() => trls[ResumeButtonSendMessage.trls.title]}
            activatorRef={activatorRef}
        >
            <Button
                onClick={() => resumeLetterButtonClick({ vacancyId, resumeId })}
                to={link}
                data-qa="resume__mailto-button"
                Element={SPALink}
                icon={
                    <BlokoIconReplaceContainer>
                        <EnvelopeOutlinedSize16 initialColor="secondary" />
                    </BlokoIconReplaceContainer>
                }
                innerRef={activatorRef}
            />
        </HoverTip>
    );
};

ResumeButtonSendMessage.trls = {
    title: 'resume.button.writeLetter',
};

export default connect((state) => ({
    userType: state.userType,
    permission: state.resume?.permission,
    isEmailEmpty: !state.resume?.email?.value,
    resumeId: state.resume?.id,
    vacancyId: state.router.location.query.vacancyId,
    link: state.resume?.hash && state.employerNegotiationLinks[state.resume?.hash]?.[TransitionType.Mail]?.defaultLink,
}))(translation(ResumeButtonSendMessage));
