import Analytics, { ElementShownAnchor } from '@hh.ru/analytics-js';
import {
    Button,
    Card,
    Text,
    Title,
    useBreakpoint,
    VSpacing as MagritteVSpacing,
    VSpacingContainer,
} from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import Gap from 'bloko/blocks/gap';
import { H3 } from 'bloko/blocks/header';
import BlokoLink from 'bloko/blocks/link';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import GetAppSMS from 'src/components/GetAppSMS';
import InfoBlockSubstrate, { InfoBlockSubstrateKind } from 'src/components/InfoBlockSubstrate';
import StaticImg from 'src/components/StaticImg';
import translation from 'src/components/translation';
import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';
import { Permission } from 'src/models/resume/resumeCommon.types';
import { UserType } from 'src/models/userType';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import styles from './suitable-gifts-promo.less';

const TrlKeys = {
    title: 'suitable.resume.gifts.resume.header',
    description: 'suitable.resume.gifts.description.3',
    priceLink: 'suitable.resume.gifts.description.link',
    titleOnVacancyResponses: 'suitable.resume.gifts_experiment.getAppSMS.label',
    expTitle: 'employer.banner.suitable-gifts-promo.title',
    expDescription: 'employer.banner.suitable-gifts-promo.description',
    logoAltText: 'employer.banner.suitable-gifts-promo.logo.alt-text',
    qrCodeAltText: 'employer.banner.suitable-gifts-promo.qr-code.alt-text',
    downloadButton: 'employer.banner.suitable-gifts-promo.download-button',
};

interface SuitableGiftsPromo {
    fromVacancyResponses?: boolean;
}

// tempexp_27846_start
const mobileAppBannerElementShown = (
    element: HTMLElement,
    { vacancyId, employerManagerId }: { vacancyId: string; employerManagerId: string }
) => Analytics.sendHHEventElementShown(element, { elementName: 'hh_business_banner', vacancyId, employerManagerId });
// tempexp_27846_end

const SuitableGiftsPromo: TranslatedComponent<SuitableGiftsPromo> = ({ trls, fromVacancyResponses = false }) => {
    const stayInTouchApps = useSelector((state) => state.stayInTouch.apps);
    const isEmployer = useSelector((state) => state.userType === UserType.Employer);
    const isGiftResumeWithoutContacts = useSelector(
        (state) => !!state.resume?.isGift && state.resume?.permission === Permission.ViewWithoutContacts
    );
    const { isXS } = useBreakpoint();
    const hide = !(isEmployer && (isGiftResumeWithoutContacts || fromVacancyResponses));
    const isRedesignExp = useExperiment('redesign_hh_business_banner', !hide);

    // tempexp_27846_start
    const vacancyId = useSelector((state) => state.router.location.query?.vacancyId);
    const employerManagerId = useSelector((state) => state.employerManager?.id || '');
    const analyticParams = { vacancyId, employerManagerId };
    // tempexp_27846_end

    const sendAnalytics = (name: string) => {
        // tempexp_27846_next_line
        Analytics.sendHHEventButtonClick('download_button', { name, ...analyticParams });
        fromVacancyResponses && Analytics.sendEvent('mobile_application', name, 'resume_page');
    };

    if (hide) {
        return null;
    }

    return (
        <ElementShownAnchor fn={mobileAppBannerElementShown} {...analyticParams}>
            {isRedesignExp ? (
                <>
                    {!fromVacancyResponses && <MagritteVSpacing default={24} />}
                    <Card style="secondary" padding={24} borderRadius={24} stretched>
                        <div className={styles.contentWrapper}>
                            <div className={styles.info}>
                                <StaticImg
                                    className={styles.logo}
                                    path="/images/hh/hh-business/logo.svg"
                                    alt={trls[TrlKeys.logoAltText]}
                                />
                                <VSpacingContainer default={4}>
                                    <Title Element="h5" size="small">
                                        {trls[TrlKeys.expTitle]}
                                    </Title>
                                    {!isXS && <Text>{trls[TrlKeys.expDescription]}</Text>}
                                </VSpacingContainer>
                            </div>
                            {!isXS && (
                                <StaticImg
                                    className={styles.qrCode}
                                    path="/images/hh/hh-business/qr-code.png"
                                    alt={trls[TrlKeys.qrCodeAltText]}
                                />
                            )}
                        </div>
                        {isXS && (
                            <>
                                <MagritteVSpacing default={16} />
                                <Button
                                    Element={SPALink}
                                    to="/emp/qr_employer_page"
                                    mode="primary"
                                    style="accent"
                                    onClick={() =>
                                        Analytics.sendHHEventButtonClick('redesign_download_button', analyticParams)
                                    }
                                    stretched
                                >
                                    {trls[TrlKeys.downloadButton]}
                                </Button>
                            </>
                        )}
                    </Card>
                </>
            ) : (
                <InfoBlockSubstrate kind={InfoBlockSubstrateKind.Tertiary}>
                    <div className="info-block-gifts" data-qa="suitable-resume-gifts">
                        <Gap right>
                            {fromVacancyResponses ? (
                                <H3>{trls[TrlKeys.titleOnVacancyResponses]}</H3>
                            ) : (
                                <>
                                    <H3>{trls[TrlKeys.title]}</H3>
                                    <VSpacing base={4} />
                                    <p>
                                        {trls[TrlKeys.description]}
                                        {NON_BREAKING_SPACE}
                                        <BlokoLink
                                            disableVisited
                                            Element={SPALink}
                                            to="/price/dbaccess"
                                            onClick={() =>
                                                Analytics.sendHHEventButtonClick('price_link', analyticParams)
                                            }
                                        >
                                            {trls[TrlKeys.priceLink]}
                                        </BlokoLink>
                                    </p>
                                </>
                            )}
                            <GetAppSMS
                                analyticsFrom={fromVacancyResponses ? 'vacancyResponses' : 'resumePage'}
                                sendAnalytics
                                {...analyticParams}
                            />
                        </Gap>
                        {fromVacancyResponses && <div className={styles.giftsPromoContentSpacer} />}
                        <div className="stay-in-touch stay-in-touch_inline-s">
                            <div className="stay-in-touch__apps">
                                {stayInTouchApps.map(({ name, href }) => (
                                    <SPALink
                                        onMouseDown={() => sendAnalytics(name)}
                                        onKeyDown={() => sendAnalytics(name)}
                                        key={name}
                                        to={href}
                                        className={`stay-in-touch__link stay-in-touch__link_${name}`}
                                        rel="noopener"
                                        target="_blank"
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </InfoBlockSubstrate>
            )}
        </ElementShownAnchor>
    );
};

export default translation(SuitableGiftsPromo);
