import { useCallback } from 'react';

import { WEBCALL_CUSTOM_EVENTS } from 'src/components/Webcall/const';
import { WebCallData } from 'src/components/Webcall/types';

type InitCallInput = Omit<WebCallData, 'widgetId'>;

interface HookResult {
    initCall: (data: InitCallInput) => void;
}

export default function (): HookResult {
    const initCall = useCallback((callData: InitCallInput) => {
        window.dispatchEvent(
            new CustomEvent(WEBCALL_CUSTOM_EVENTS.INIT_CALL, {
                detail: {
                    ...callData,
                    widgetId: 'webcall-front-dynamic',
                },
            })
        );
    }, []);

    return {
        initCall,
    };
}
