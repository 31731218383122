import { memo } from 'react';
import classnames from 'classnames';

import Column, { ColumnsRow } from 'bloko/blocks/column';
import { TranslationLangContext } from 'bloko/common/hooks/useTranslations';

import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';
import useGetMColumnSize from 'src/pages/ResumeView/hooks/useGetMColumnSize';
import { EDIT } from 'src/utils/constants/resumePermission';
import { BLOCKED, CORRECTED } from 'src/utils/constants/resumeStatuses';

import ResumeCommentsSidebar from 'src/pages/ResumeView/components/View/ResumeSidebar/Comments/Sidebar';
import HistorySidebar from 'src/pages/ResumeView/components/View/ResumeSidebar/History/HistorySidebar';
import ResumeInterviewSidebar from 'src/pages/ResumeView/components/View/ResumeSidebar/Interview';
import ResumePublish from 'src/pages/ResumeView/components/View/ResumeSidebar/ResumePublish';
import TestShlSidebar from 'src/pages/ResumeView/components/View/ResumeSidebar/Tests/TestShlSidebar';
import VacancyTestSidebar from 'src/pages/ResumeView/components/View/ResumeSidebar/Tests/VacancySidebar';
import ResumeViewHistorySidebar from 'src/pages/ResumeView/components/View/ResumeSidebar/ViewHistory/Sidebar';

const ALLOWED_USER_TYPES = new Set([UserType.Employer, UserType.BackOffice]);

const ResumeSidebar = ({ topicId }) => {
    const { mSidebar } = useGetMColumnSize();
    const currentInterview = useSelector((state) => state.scheduledInterviews?.[topicId]?.currentInterview);
    const canEdit = useSelector((state) => state.resume?.permission === EDIT);
    const percent = useSelector((state) => state.resume?.percent);
    const status = useSelector((state) => state.resume?.status);
    const userType = useSelector((state) => state.userType);
    const lang = useSelector((state) => state.langs[0]);

    if ((percent === 0 || !canEdit) && !ALLOWED_USER_TYPES.has(userType)) {
        return null;
    }

    return (
        <ColumnsRow>
            <Column xs="0" s="0" m={mSidebar} l="4" container>
                <div
                    className={classnames('resume-sidebar-background', {
                        'resume-sidebar-background_error': status === BLOCKED,
                        'resume-sidebar-background_warn': status === CORRECTED,
                        'resume-sidebar-background_mwide': mSidebar === '4',
                    })}
                />
                <ColumnsRow data-qa="resume-sidebar">
                    <ResumePublish />
                    {userType === UserType.Employer && (
                        <TranslationLangContext.Provider value={lang}>
                            {currentInterview && <ResumeInterviewSidebar currentInterview={currentInterview} />}
                            <ResumeCommentsSidebar isFirstSideBlock={!currentInterview} />
                            <TestShlSidebar />
                            <VacancyTestSidebar />
                            <HistorySidebar />
                            <ResumeViewHistorySidebar />
                        </TranslationLangContext.Provider>
                    )}
                    {userType === UserType.BackOffice && (
                        <TranslationLangContext.Provider value={lang}>
                            <HistorySidebar />
                        </TranslationLangContext.Provider>
                    )}
                </ColumnsRow>
            </Column>
        </ColumnsRow>
    );
};

export default memo(ResumeSidebar);
