import { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import Button, { ButtonKind, ButtonType } from 'bloko/blocks/button';
import { H1Section } from 'bloko/blocks/header';
import Link, { LinkAppearance, LinkKind } from 'bloko/blocks/link';
import Modal from 'bloko/blocks/modal';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

import Form from 'src/components/Form';
import translation from 'src/components/translation';
import useFingerprints from 'src/hooks/useFingerprints';
import { NO_ONE } from 'src/models/resume/resumeAccess.types';

const ResumeNudge = ({ hash, accessType, source, applicantInfo, trls }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const closeModal = useCallback(() => setModalVisible(false), []);
    const fingerprints = useFingerprints('applicant');
    const [fingerprintIteration2, setFingerprintIteration2] = useState(fingerprints.fingerprintIteration2);
    const [fingerprintSp, setFingerprintSp] = useState(fingerprints.fingerprintSp);
    const submitForm = () => {
        setFingerprintIteration2(fingerprints.fingerprintIteration2);
        setFingerprintSp(fingerprints.fingerprintSp);
    };

    useEffect(
        () => {
            // для анимации показа bloko-modal-overlay надо открывать modal асинхронно
            setTimeout(() => setModalVisible(true), 0);
        },
        [] // надо выполнить только для mount
    );

    const isFirstPublish = !applicantInfo.finished;

    return (
        <Modal visible={modalVisible} onClose={closeModal}>
            <div className="resume-nudge-modal">
                <H1Section>{trls[ResumeNudge.trls.header]}</H1Section>
                <VSpacing base={4} />
                <div className="resume-nudge__text">{trls[ResumeNudge.trls.text]}</div>
                <div className="resume-nudge__visibility-status">
                    {`${trls[ResumeNudge.trls.visibilityText]} `}
                    <strong>{trls[ResumeNudge.trls[`accessType.${accessType}`]]}</strong>
                </div>
                <Form action="/applicant/resumes/touch" method="POST" onSubmit={submitForm}>
                    <input type="hidden" name="resume" value={hash} />
                    <input type="hidden" name="publish" value={isFirstPublish ? 'first' : 'next'} />
                    <input type="hidden" name="fingerprintIteration2" value={fingerprintIteration2} />
                    <input type="hidden" name="fingerprintSp" value={fingerprintSp} />
                    {source === 'short' && <input type="hidden" name="publish-short" value="full" />}
                    {accessType !== NO_ONE && <input type="hidden" name="createVisibleResume" value="true" />}
                    <Button type={ButtonType.Submit} kind={ButtonKind.Success}>
                        {trls[ResumeNudge.trls.ok]}
                    </Button>
                    <span className="resume-nudge__cancel">
                        <Text Element="span" size={TextSize.Small}>
                            <Link appearance={LinkAppearance.Pseudo} kind={LinkKind.Secondary} onClick={closeModal}>
                                {trls[ResumeNudge.trls.cancel]}
                            </Link>
                        </Text>
                    </span>
                </Form>
            </div>
        </Modal>
    );
};

ResumeNudge.trls = {
    ok: 'rb.nudge.button.ok',
    cancel: 'rb.nudge.button.cancel',
    header: 'rb.nudge.header',
    text: 'rb.nudge.text',
    visibilityText: 'rb.nudge.visibility.text',
    'accessType.everyone': 'rb.visibility.status.everyone',
    'accessType.clients': 'rb.visibility.status.clients',
    'accessType.whitelist': 'rb.visibility.status.whitelist',
    'accessType.blacklist': 'rb.visibility.status.blacklist',
    'accessType.direct': 'rb.visibility.status.direct',
    'accessType.no_one': 'rb.visibility.status.no_one',
};

export default connect((state) => ({
    hash: state.resume?.hash,
    accessType: state.resume?.accessType,
    source: state.resume?.source,
    applicantInfo: state.applicantInfo,
}))(translation(ResumeNudge));
