import { connect } from 'react-redux';

import Column from 'bloko/blocks/column';

import translation from 'src/components/translation';
import useGetMColumnSize from 'src/pages/ResumeView/hooks/useGetMColumnSize';

import ResumeBlock from 'src/pages/ResumeView/components/View/ResumeBlock';
import ResumeEditLink from 'src/pages/ResumeView/components/View/ResumeEditLink';
import ResumeImageLoader from 'src/pages/ResumeView/components/View/ResumeImageLoader';

const ResumePortfolio = ({ portfolioUrls, trls }) => {
    const { mMain } = useGetMColumnSize();
    if (!portfolioUrls.length) {
        return null;
    }
    return (
        <ResumeBlock
            title={trls[ResumePortfolio.trls.title]}
            EditLink={<ResumeEditLink block="experience" field="portfolio" data-qa="resume-block-portfolio-edit" />}
            data-qa="resume-block-portfolio"
        >
            <Column xs="4" s="8" m={mMain} l="12">
                <div className="resume-block-container">
                    <div className="form__popup m-resume_portfolio">
                        <div className="resume-block__portfolio-wrapper">
                            {portfolioUrls.map((project) => (
                                <ResumeImageLoader
                                    key={project.id}
                                    preview={project.preview}
                                    big={project.big}
                                    title={project.title}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </Column>
        </ResumeBlock>
    );
};

ResumePortfolio.trls = {
    title: 'rb.title.portfolio',
};

export default connect((state) => ({
    portfolioUrls: state.resume?.portfolioUrls.value,
}))(translation(ResumePortfolio));
