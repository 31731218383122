import { ElementShownAnchor } from '@hh.ru/analytics-js';
import buttonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/hhpro/hhpro_activate_resume_button_click';
import elementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/hhpro/hhpro_activate_resume_element_shown';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import Column, { ColumnsRow } from 'bloko/blocks/column';
import { H4 } from 'bloko/blocks/header';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import useGetMColumnSize from 'src/pages/ResumeView/hooks/useGetMColumnSize';
import { HH_PRO_LINK } from 'src/utils/constants/applicantServices';

import styles from './index.less';

const TrlKeys = {
    title: 'resume.findJobPromo.hhPro.title',
    description: 'resume.findJobPromo.hhPro.description',
    more: 'resume.findJobPromo.hhPro.more',
};

const FindJobHhPro: TranslatedComponent = ({ trls }) => {
    const { mSidebar } = useGetMColumnSize();

    return (
        <ElementShownAnchor className={styles.hhProBackground} fn={elementShown}>
            <div className={styles.hhProImageContainer} onClick={() => buttonClick()}>
                <ColumnsRow>
                    <Column xs="4" s="8" m={mSidebar} l="4">
                        <div className="resume-sidebar-section resume-sidebar-section_no-margin">
                            <div className={styles.hhProContent}>
                                <H4>{trls[TrlKeys.title]}</H4>
                                <VSpacing base={4} />
                                <Text importance={TextImportance.Secondary}>{trls[TrlKeys.description]}</Text>

                                <div className="resume-sidebar-link">
                                    <SPALink to={HH_PRO_LINK}>{trls[TrlKeys.more]}</SPALink>
                                </div>
                            </div>
                        </div>
                    </Column>
                </ColumnsRow>
                <div className={styles.hhProImage} />
            </div>
        </ElementShownAnchor>
    );
};

export default translation(FindJobHhPro);
