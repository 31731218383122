import { useCallback, useEffect, useRef } from 'react';
import axios from 'axios';

import { fetcher } from 'src/utils/fetcher';

const EMPTY_PARTS = {};
const DEFAULT_PARSE_ERROR = 'parse_error';

/**
 * Колбэк, вызываемый перед парсингом
 * @typedef {Function} OnParseBegin
 * @param {Object} params параметры колбэка
 * @param {String} params.value данные, которые нужно отвалидировать асинхронно
 */

/**
 * Колбэк, вызываемый после парсинга.
 * В параметрах передаётся составляющие телефона, отформатированный телефон или ошибка
 * @typedef {Function} OnParseEnd
 * @param {Object} params параметры колбэка
 * @param {Object} params.base значение, которое было на входе
 * @param {String} [params.value] исправленное значение
 * @param {Object} params.parts дополнительные данные пришедшие с бека. Например части value, из которых он состоит
 * @param {String} [params.error] ошибка, возникшая при парсинге
 */

/**
 * Триггер, инициирующий парсинг
 * @typedef {Function} StartParse
 * @param {String} value данные, которые нужно распарсить
 */

/**
 * Триггер, останвливающий парсинг
 * @typedef {Function} StopParse
 */

/**
 * Триггеры для управления парсингом
 * @typedef {Object} PhoneParserTriggers
 * @property {StartParse} startParse триггер, инициирующий парсинг
 * @property {StopParse} stopParse триггер, останвливающий парсинг
 */

/**
 * Хук для парсинга
 * @param {Object} params параметры хука
 * @param {OnParseBegin} params.onParseBegin колбэк, вызываемый перед парсингом
 * @param {OnParseEnd} params.onParseEnd колбэк, вызываемый после парсинга
 * @returns {PhoneParserTriggers} триггеры для управления парсингом
 */
const useAsyncFieldValidator = ({
    url,
    valueParam = 'value',
    onParseBegin,
    onParseEnd,
}: {
    url: keyof FetcherPostApi;
    valueParam?: string;
    onParseBegin: (k: { value: string }) => void;
    onParseEnd: (k: { value: string; base?: string; parts: Record<string, unknown>; error?: string }) => void;
}): { startParse: (value: string) => Promise<string | undefined>; stopParse: () => void } => {
    const onParseBeginRef = useRef(onParseBegin);
    const onParseEndRef = useRef(onParseEnd);

    const cancelParseRef = useRef<() => void>();
    const stopParse = useCallback(() => cancelParseRef.current?.(), []);

    const startParse = useCallback(
        async (value: string): Promise<string | undefined> => {
            stopParse();
            onParseBeginRef.current({ value });

            if (!value) {
                onParseEndRef.current({ value, parts: EMPTY_PARTS });
                return undefined;
            }

            const cancelToken = new axios.CancelToken((cancel) => {
                cancelParseRef.current = cancel;
            });

            const oldValue = value;
            return fetcher.post(url, { [valueParam]: value }, { cancelToken }).then(
                ({ data }) => {
                    const { value, ...parts } = data as { value: string };
                    onParseEndRef.current({ base: oldValue, value, parts });
                    return undefined;
                },
                (reason?: { response: { data: { error: string } } }) => {
                    if (fetcher.isCancel(reason)) {
                        onParseEndRef.current({ value: oldValue, parts: EMPTY_PARTS });
                        return undefined;
                    }
                    onParseEndRef.current({
                        value: oldValue,
                        error: reason?.response?.data?.error || DEFAULT_PARSE_ERROR,
                        parts: EMPTY_PARTS,
                    });
                    return reason?.response?.data?.error || DEFAULT_PARSE_ERROR;
                }
            );
        },
        [stopParse, url, valueParam]
    );

    useEffect(() => {
        onParseBeginRef.current = onParseBegin;
        onParseEndRef.current = onParseEnd;
    }, [onParseBegin, onParseEnd]);

    return { startParse, stopParse };
};

export default useAsyncFieldValidator;
