import { Fragment } from 'react';
import classnames from 'classnames';

import Text, { TextImportance, TextSize } from 'bloko/blocks/text';

import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import useToggleState from 'src/hooks/useToggleState';

const ResumeExperienceIndustries = ({ industries, trls }) => {
    const isVisibleAtPrint = useSelector((state) => !!state.printVersion.industries);
    const [allVisible, toggleVisible] = useToggleState(isVisibleAtPrint);

    if (!industries) {
        return null;
    }

    return (
        <div
            className={classnames('resume-block__experience-industries', {
                'resume-block_no-print': !isVisibleAtPrint,
            })}
        >
            {industries
                .filter((industry) => industry.children.length !== 0)
                .map((industry, index) => {
                    if (!allVisible && index > 0) {
                        return null;
                    }
                    return (
                        <Fragment key={industry.id}>
                            <p>
                                <AsyncHighlighter>{industry.title}</AsyncHighlighter>
                                {!allVisible && (
                                    <span className="resume-industries__open" onClick={toggleVisible}>
                                        <Text
                                            Element="span"
                                            size={TextSize.Small}
                                            importance={TextImportance.Secondary}
                                        >
                                            {trls[ResumeExperienceIndustries.trls.industriesOpen]}
                                        </Text>
                                    </span>
                                )}
                            </p>
                            {allVisible && (
                                <ul className="profareatree__sublist profareatree__sublist_experience">
                                    {industry.children.map((subIndustry) => {
                                        return (
                                            <li key={subIndustry.id} className="profareatree__subitem-experience">
                                                <AsyncHighlighter>{subIndustry.title}</AsyncHighlighter>
                                            </li>
                                        );
                                    })}
                                </ul>
                            )}
                        </Fragment>
                    );
                })}
        </div>
    );
};

ResumeExperienceIndustries.trls = {
    industriesOpen: 'company.employer.industries.open',
};

export default translation(ResumeExperienceIndustries);
