import { useRef } from 'react';

import Column from 'bloko/blocks/column';
import ConversionNumber from 'bloko/blocks/conversion';
import HoverTip, { TipPlacement } from 'bloko/blocks/drop/Tip/HoverTip';
import Link, { LinkAppearance, LinkKind } from 'bloko/blocks/link';
import TagList from 'bloko/blocks/tagList';
import Tag from 'bloko/blocks/tagList/tag';

import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import useGetMColumnSize from 'src/pages/ResumeView/hooks/useGetMColumnSize';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import ResumeBlock from 'src/pages/ResumeView/components/View/ResumeBlock';

const ResumeProviderSkills = ({ trls }) => {
    const { mMain } = useGetMColumnSize();
    const providerKeySkills = useSelector((state) => state.resume?.keySkillsConfirmedByProviders.value);
    const skillsWithProviders = Object.values(providerKeySkills?.skillIdsToProviders || {});
    const activatorLinkRef = useRef(null);
    const activatorTemplateRef = useRef(null);

    if (!skillsWithProviders.length) {
        return null;
    }

    const { promotedProviders, otherProviders } = providerKeySkills;
    const havePromotedProviders = promotedProviders.length > 0;
    const renderProviders = (providers) => () => providers.map(({ name, id }) => <div key={id}>{name}</div>);

    return (
        <ResumeBlock
            title={trls[ResumeProviderSkills.trls.providerSkills]}
            subTitle={
                <>
                    {trls[ResumeProviderSkills.trls.providerSkills]}{' '}
                    {promotedProviders.map(({ name }) => name).join(', ')}
                    {otherProviders.length > 0 && (
                        <>
                            {NON_BREAKING_SPACE}
                            {havePromotedProviders && trls[ResumeProviderSkills.trls.and]}{' '}
                            <HoverTip
                                placement={TipPlacement.Top}
                                render={renderProviders(otherProviders)}
                                activatorRef={activatorLinkRef}
                            >
                                <Link
                                    appearance={LinkAppearance.Pseudo}
                                    kind={LinkKind.Tertiary}
                                    ref={activatorLinkRef}
                                >
                                    {havePromotedProviders && trls[ResumeProviderSkills.trls.moreProviders]}
                                    {NON_BREAKING_SPACE}
                                    <ConversionNumber
                                        value={otherProviders.length}
                                        one={trls[ResumeProviderSkills.trls.oneProvider]}
                                        some={trls[ResumeProviderSkills.trls.someProviders]}
                                        many={trls[ResumeProviderSkills.trls.someProviders]}
                                    />
                                </Link>
                            </HoverTip>
                        </>
                    )}
                </>
            }
        >
            <Column xs="4" s="8" m={mMain} l="12">
                <div className="resume-block-container">
                    <TagList
                        items={skillsWithProviders.map(({ skillId, skillName, providers }) => (
                            <Tag key={skillId}>
                                <HoverTip
                                    placement={TipPlacement.Top}
                                    render={renderProviders(providers)}
                                    host={!process.env.SSR ? document.body : null}
                                    activatorRef={activatorTemplateRef}
                                >
                                    <div className="resume-key-skills-matched-template" ref={activatorTemplateRef}>
                                        <AsyncHighlighter>{skillName}</AsyncHighlighter>
                                    </div>
                                </HoverTip>
                            </Tag>
                        ))}
                    />
                </div>
            </Column>
        </ResumeBlock>
    );
};

ResumeProviderSkills.trls = {
    and: 'and',
    providerSkills: 'resume.experience.providerKeySkills',
    moreProviders: 'resume.experience.providerKeySkills.providers.more',
    oneProvider: 'resume.experience.providerKeySkills.providers.one',
    someProviders: 'resume.experience.providerKeySkills.providers.some',
};

export default translation(ResumeProviderSkills);
