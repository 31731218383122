import { useMemo } from 'react';

import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';
import { EDIT } from 'src/utils/constants/resumePermission';

const Features = {
    enabledMagritteBackofficeResumeView: 'enabled_magritte_backoffice_resume_view',
};

export const useUserType = () => {
    const userType = useSelector(({ userType }) => userType);

    return useMemo(
        () => ({
            isApplicant: UserType.Applicant === userType,
            isBackOffice: UserType.BackOffice === userType,
        }),
        [userType]
    );
};

export const useMagrittePageEnabled = () => {
    const canEdit = useSelector((state) => state.applicantResume?._attributes?.permission === EDIT);
    const isPrintVersion = useSelector(({ printVersion }) => printVersion.isPrintVersion);
    const enabledMagritteBackofficeResumeView = useSelector(
        ({ features }) => features[Features.enabledMagritteBackofficeResumeView] as boolean
    );
    const isMagritteExp = useExperiment('magritte_appl_resume_view_v2');
    const { isApplicant, isBackOffice } = useUserType();

    if (isPrintVersion || !canEdit) {
        // пока используем старую форму печати
        return false;
    }

    if (isBackOffice && enabledMagritteBackofficeResumeView) {
        // магритт версию страницы открываем для всего бэкофиса под динамической настройкой
        return true;
    }

    if (isApplicant && isMagritteExp) {
        return true;
    }

    return false;
};
