import { ComponentType } from 'react';

import { Input } from '@hh.ru/magritte-ui';

import useAsyncFieldValidator from 'src/hooks/useAsyncFieldValidator';
import { PhoneType } from 'src/models/applicant/resume/blocks/contacts/types';

interface Props {
    type: PhoneType;
    value: string;
    invalid: boolean;
    onChange: (value: string) => void;
    onFormat?: () => void;
    placeholder?: string;
    errorMessage?: string | null;
}

const PHONE_PARSE_URL = '/shards/resume/phone_parse';

const PhoneFormatter: ComponentType<Props> = ({ value, type, invalid, onChange, placeholder, errorMessage }) => {
    const { startParse } = useAsyncFieldValidator({
        url: PHONE_PARSE_URL,
        onParseBegin: () => {},
        onParseEnd: ({ value }) => {
            onChange(value);
        },
    });

    const handleBlur = () => {
        void startParse(value);
    };

    return (
        <Input
            data-qa={`resume-phone-${type}_phone`}
            size="medium"
            value={value}
            onBlur={handleBlur}
            invalid={invalid}
            onChange={onChange}
            inputMode="tel"
            placeholder={placeholder}
            errorMessage={errorMessage}
        />
    );
};

export default PhoneFormatter;
