import { connect } from 'react-redux';

import Column from 'bloko/blocks/column';
import TagList from 'bloko/blocks/tagList';

import LanguageTagInner from 'src/components/Languages/LanguageTagInner';
import translation from 'src/components/translation';
import { useProfile } from 'src/hooks/useProfile';
import useGetMColumnSize from 'src/pages/ResumeView/hooks/useGetMColumnSize';

import ResumeBlock from 'src/pages/ResumeView/components/View/ResumeBlock';
import ResumeEditLink from 'src/pages/ResumeView/components/View/ResumeEditLink';

const ResumeLanguages = ({ language, trls, verifiedSkills }) => {
    const { mMain } = useGetMColumnSize();
    if (useProfile()) {
        return null;
    }
    return (
        <ResumeBlock
            title={trls[ResumeLanguages.trls.title]}
            data-qa="resume-block-languages"
            EditLink={<ResumeEditLink block="education" field="language" data-qa="resume-block-language-edit" />}
        >
            <Column xs="4" s="8" m={mMain} l="12">
                <TagList
                    items={language.map((languageItem) => {
                        const isVerified = verifiedSkills?.some(
                            (vSkill) => vSkill.id === languageItem.id && vSkill.level.internalId === languageItem.degree
                        );

                        return (
                            <LanguageTagInner language={languageItem} isVerified={isVerified} key={languageItem.id} />
                        );
                    })}
                />
            </Column>
        </ResumeBlock>
    );
};

ResumeLanguages.trls = {
    title: 'rb.title.languages',
};

export default connect((state) => ({
    language: state.resume?.language.value,
    verifiedSkills: state.resume?.verifiedSkills,
}))(translation(ResumeLanguages));
