import { Link } from '@hh.ru/magritte-ui';
import useBreakpoint from '@hh.ru/magritte-ui-breakpoint/useBreakpoint';
import { MapPinOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import ResumeMapModal from 'src/pages/ResumeView/employerRedesign/components/ResumeMapModal';
import { useResumeMapSearchPoints } from 'src/pages/ResumeView/employerRedesign/utils';

import styles from './styles.less';

const TrlKeys = {
    link: {
        default: 'resume.map.link',
        short: 'resume.map.link.short',
    },
};

const ResumeMap: TranslatedComponent = ({ trls }) => {
    const mapSearchPoints = useResumeMapSearchPoints();
    const { isMobile } = useBreakpoint();
    const [mapModalVisibility, showMapModal, hideMapModal] = useOnOffState(false);

    if (!mapSearchPoints) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <Link
                style="accent"
                typography="label-3-regular"
                iconLeft={<MapPinOutlinedSize16 />}
                onClick={showMapModal}
            >
                {trls[TrlKeys.link[isMobile ? 'short' : 'default']]}
            </Link>
            <ResumeMapModal visible={mapModalVisibility} onClose={hideMapModal} />
        </div>
    );
};

export default translation(ResumeMap);
