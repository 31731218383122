import { useMemo } from 'react';

import { SPALink } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import CareerPlatformBanner from 'src/components/CareerPlatformBanner/index';
import ApplicantSkillsVerificationMethodsCard from 'src/components/ResumeView/ApplicantSkillsVerificationMethodsCard';
import translation from 'src/components/translation';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';
import { useIsHeadHunterPlatform } from 'src/hooks/usePlatform';
import { useSelectorNonNullable, useSelector } from 'src/hooks/useSelector';
import { JobSearchStatus } from 'src/models/applicantUserStatuses';
import { CareerBannerPosition, CareerLinksKeys } from 'src/models/careerPlatformState';
import { Permission } from 'src/models/resume/resumeCommon.types';
import { UserType } from 'src/models/userType';
import useGetMColumnSize from 'src/pages/ResumeView/hooks/useGetMColumnSize';

import ResumeBlock from 'src/pages/ResumeView/components/View/ResumeBlock';
import ResumeEditLink from 'src/pages/ResumeView/components/View/ResumeEditLink';
// будет выпиливаться в этом квартале
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ResumeProviderSkills from 'src/pages/ResumeView/components/View/ResumeProviderSkills';
import ResumeSkillLevels from 'src/pages/ResumeView/components/View/ResumeSkillLevels/ResumeSkillLevels';

const TrlKeys = {
    title: 'rb.title.keySkills',
    titleWithCompetence: 'rb.title.keySkillsWithCompetence',
    skillVerifiedTip: 'resume.skills.verification.tip',
    keySkillsInfoTip: 'key.skills.info.tip',
    keySkillsDescription: 'key.skills.edit.description',
    keySkillsEditLink: 'key.skills.edit.link',
};

const ResumeKeySkills: TranslatedComponent = ({ trls }) => {
    const { mMain } = useGetMColumnSize();
    const isProviderSkillsEnabled = useFeatureEnabled('provider_skills');
    const isEmployer = useSelector((state) => state.userType === UserType.Employer);
    const applicantSkillsVerificationMethodsRaw = useSelector(
        ({ applicantSkillsVerificationMethods }) => applicantSkillsVerificationMethods
    );
    const applicantSkillsVerificationMethods = useMemo(
        () => applicantSkillsVerificationMethodsRaw.filter((method) => method.availability.status === 'AVAILABLE'),
        [applicantSkillsVerificationMethodsRaw]
    );
    const applicantSkillsVerificationExpiring = useSelector((state) => state.applicantSkillsVerificationExpiring);
    const linksState = useSelector((state) => state.careerPlatformLinks);
    const canEdit = useSelector((state) => state.resume?.permission === Permission.Edit);
    const resume = useSelectorNonNullable((state) => state.resume);
    const resumeHash = resume.hash;
    const advancedKeySkills = resume.advancedKeySkills.value || [];
    const userId = resume.user;
    const applicantUserStatus = resume.applicantUserStatuses?.[userId];

    const showCareerBanner = Boolean(
        linksState?.links &&
            [JobSearchStatus.ActiveSearch, JobSearchStatus.LookingForOffers].includes(
                applicantUserStatus?.jobSearchStatus?.name
            )
    );

    const isHeadHunterPlatform = useIsHeadHunterPlatform();

    const skillsToRender = {
        regular: advancedKeySkills,
    };

    return (
        <>
            {isEmployer && isProviderSkillsEnabled && <ResumeProviderSkills />}
            {skillsToRender.regular?.length > 0 && (
                <ResumeBlock
                    title={trls[TrlKeys.title]}
                    data-qa="skills-table"
                    EditLink={
                        <ResumeEditLink block="experience" field="keySkills" data-qa="resume-block-key-skills-edit" />
                    }
                >
                    <Column xs="4" s="8" m={mMain} l="12">
                        <div className="resume-block-container">
                            <>
                                {!isEmployer && (
                                    <div className="resume-skills-edit-info">
                                        <Text size={TextSize.Medium}>{trls[TrlKeys.keySkillsDescription]}</Text>
                                        {canEdit && (
                                            <SPALink
                                                to={`/applicant/resumes/edit/skills_levels?resume=${resumeHash}&withBacklink=true`}
                                            >
                                                {trls[TrlKeys.keySkillsEditLink]}
                                            </SPALink>
                                        )}
                                    </div>
                                )}
                                <ResumeSkillLevels
                                    skills={skillsToRender.regular}
                                    data-qa="resume-block-key-skills-levels"
                                />
                                {((isHeadHunterPlatform && applicantSkillsVerificationMethods?.length > 0) ||
                                    applicantSkillsVerificationExpiring.length > 0) && (
                                    <div className="noprint">
                                        <VSpacing base={6} />
                                        <ApplicantSkillsVerificationMethodsCard
                                            applicantSkillsVerificationMethods={applicantSkillsVerificationMethods}
                                            applicantSkillsVerificationExpiring={applicantSkillsVerificationExpiring}
                                        />
                                    </div>
                                )}
                                {showCareerBanner && (
                                    <>
                                        <VSpacing base={6} />
                                        <CareerPlatformBanner
                                            position={CareerBannerPosition.Resume}
                                            destination={CareerLinksKeys.Courses}
                                        />
                                    </>
                                )}
                            </>
                        </div>
                    </Column>
                </ResumeBlock>
            )}
        </>
    );
};

export default translation(ResumeKeySkills);
