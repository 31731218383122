import { ComponentType, useMemo, useState } from 'react';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import bannerCloseClick from '@hh.ru/analytics-js-events/build/xhh/common/design_system/banner_close_button_click';
import bannerShown from '@hh.ru/analytics-js-events/build/xhh/common/design_system/banner_element_shown';
import bannerPrimaryButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/design_system/banner_primary_button_click';
import { usePush } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import Column from 'bloko/blocks/column';
import DropInfoInline from 'bloko/blocks/drop/InfoInline';
import { SpacingMultipliers } from 'bloko/blocks/hSpacing';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import translation from 'src/components/translation';
import { useProfile } from 'src/hooks/useProfile';
import { useSelector } from 'src/hooks/useSelector';
import useGetMColumnSize from 'src/pages/ResumeView/hooks/useGetMColumnSize';
import { fetcher } from 'src/utils/fetcher';

declare global {
    interface FetcherPostApi {
        '/shards/profile/hide_banner': {
            body: undefined;
            queryParams: undefined;
            response: undefined;
        };
    }
}

const TrlKeys = {
    title: 'profile.banner.title',
    hint: 'profile.banner.hint',
    button: 'profile.banner.button',
};

interface Props {
    mobileOnly?: boolean;
    desktopOnly?: boolean;
    topSpacing?: SpacingMultipliers;
    bottomSpacing?: SpacingMultipliers;
}

const ProfileBanner: ComponentType<Props> = translation(
    ({ trls, mobileOnly, desktopOnly, topSpacing = 0, bottomSpacing = 0 }) => {
        const push = usePush();
        const isProfile = useProfile();
        const hide = useSelector((state) => state.hideProfileBanner);
        const [localHide, setLocalHide] = useState(hide);
        const breakpoint = useBreakpoint();
        const { mMain } = useGetMColumnSize();

        const analyticsParams = useMemo(
            () =>
                ({
                    bannerName: 'profile_banner',
                    hhtmSource: 'resume_list',
                }) as const,
            []
        );

        if (desktopOnly && [Breakpoint.S, Breakpoint.XS].includes(breakpoint)) {
            return null;
        }

        if (mobileOnly && ![Breakpoint.S, Breakpoint.XS].includes(breakpoint)) {
            return null;
        }

        if (!isProfile || localHide) {
            return null;
        }

        return (
            <ElementShownAnchor fn={(element) => bannerShown(element, analyticsParams)}>
                <Column xs="4" s="8" m={mMain} l="12">
                    <VSpacing base={topSpacing} />
                    <DropInfoInline
                        noIndent={true}
                        onClose={() => {
                            bannerCloseClick(analyticsParams);
                            setLocalHide(true);
                            void fetcher.post('/shards/profile/hide_banner');
                        }}
                        render={() => (
                            <div data-qa="profile-banner">
                                <Text size={TextSize.Large} strong>
                                    {trls[TrlKeys.title]}
                                </Text>
                                <VSpacing base={2} />
                                <Text size={TextSize.Medium} importance={TextImportance.Secondary}>
                                    {trls[TrlKeys.hint]}
                                </Text>
                                <VSpacing base={2} />
                                <Button
                                    appearance={ButtonAppearance.Filled}
                                    kind={ButtonKind.Primary}
                                    onClick={() => {
                                        bannerPrimaryButtonClick({
                                            ...analyticsParams,
                                            buttonText: trls[TrlKeys.button],
                                        });
                                        push('/profile/me');
                                    }}
                                >
                                    {trls[TrlKeys.button]}
                                </Button>
                            </div>
                        )}
                        show={!localHide}
                        showCloseButton
                    />
                    <VSpacing base={bottomSpacing} />
                </Column>
            </ElementShownAnchor>
        );
    }
);

export default ProfileBanner;
