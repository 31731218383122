import { useEffect, useState, useCallback, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import talantixLinkContactsHideButtonClick from '@hh.ru/analytics-js-events/build/talantix/employer/talantix_link/talantix_link_contacts_hide_button_click';
import talantixLinkContactsHideElementShown from '@hh.ru/analytics-js-events/build/talantix/employer/talantix_link/talantix_link_contacts_hide_element_shown';
import resumeTalantixButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_talantix_button_click';
import { Button, TooltipHover } from '@hh.ru/magritte-ui';
import BlokoButton, { ButtonKind, ButtonAppearance } from 'bloko/blocks/button';
import HoverTip, { TipLayer } from 'bloko/blocks/drop/Tip/HoverTip';
import Gap from 'bloko/blocks/gap';
import { TalantixScaleSmall, IconColor } from 'bloko/blocks/icon';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import Text from 'bloko/blocks/text';

import Notices from 'Modules/Notices';
import Infotip, { InfoPlacement, InfoTheme } from 'src/components/Infotip';
import { useNotification } from 'src/components/Notifications/Provider';
import {
    talantixResumeImported,
    talantixResumeImportFailed,
    talalantixResumeImportedPromo,
} from 'src/components/Notifications/ResumeViewHeader';
import translation from 'src/components/translation';
import useGet from 'src/hooks/useGet';
import useIsClient from 'src/hooks/useIsClient';
import { useSelector } from 'src/hooks/useSelector';
import useTalantixPromoUrl, { UTM_CAMPAIGN_RESUME_TRANSFER } from 'src/hooks/useTalantixPromoUrl';
import { fetcher } from 'src/utils/fetcher';

const trackEvent = (action, label) => {
    Analytics.sendEvent('talantix', action, label);
};

const INFOTIP_NAME = 'talantixImportButton';
const INFOTIP_NAME_PROMO = 'talantixImportButtonPromo';
const TALANTIX_IMPORT_URL = 'https://talantix.ru/integrations/hh/import';

const getOnClickAction = ({
    importResults,
    setImportResults,
    data,
    addNotification,
    importInProgress,
    setImportInProgress,
    url,
    talantixPromoUrl,
}) => {
    if (importInProgress) {
        return () => {};
    }

    if (importResults) {
        return () => addNotification(talantixResumeImported, { unuqueType: true, props: importResults });
    }

    if (data?.isAuthenticated) {
        return async () => {
            try {
                setImportInProgress(true);
                const { data } = await fetcher.post(url, {
                    resume: {
                        type: 'hh',
                        link: location.href,
                    },
                });
                setImportInProgress(false);
                if (data?.link) {
                    setImportResults(data);
                    addNotification(talantixResumeImported, { unuqueType: true, props: data });
                } else {
                    addNotification(talantixResumeImportFailed, {
                        props: {
                            type: data?.error?.type,
                        },
                    });
                }
            } catch (error) {
                setImportInProgress(false);
                addNotification(talantixResumeImportFailed, {
                    props: {
                        type: error?.response?.data?.error?.type || 'network',
                    },
                });
            }
        };
    }
    return () => {
        window.open(talantixPromoUrl, '_blank');
    };
};

const useAnalyticsEvents = ({ loading, infotipActionName }) => {
    const showInfoTip = useSelector(({ infoTip }) => infoTip.name === INFOTIP_NAME);
    useEffect(() => {
        if (!loading && showInfoTip) {
            trackEvent(infotipActionName, 'show_import_infotip');
        }
    }, [loading, infotipActionName, showInfoTip]);

    const [delayedSeenEvent, setDelayedSeenEvent] = useState(false);

    useEffect(() => {
        if (delayedSeenEvent && !loading) {
            trackEvent(infotipActionName, 'close');
        }
    }, [delayedSeenEvent, infotipActionName, loading]);

    return [setDelayedSeenEvent];
};

const ResumeTalantixButton = ({ trls, isMagritte = false }) => {
    const isClient = useIsClient();
    const { addNotification } = useNotification();
    const resume = useSelector(({ resume }) => resume);
    const vacancyId = useSelector((state) => state.router.location.query.vacancyId);
    const hhid = useSelector((state) => state.hhid);
    const url = `${TALANTIX_IMPORT_URL}?hhid=${hhid}`;
    const talantixPromoUrl = useTalantixPromoUrl(UTM_CAMPAIGN_RESUME_TRANSFER);
    const activatorRef = useRef(null);

    const { data, loading } = useGet(url);

    const showInfoTipPromo = useSelector(({ infoTip }) => infoTip.name === INFOTIP_NAME_PROMO);
    const infotipActionName = data?.isAuthenticated ? 'import_infotip_for_user' : 'import_infotip_for_non_user';

    const [importInProgress, setImportInProgress] = useState(false);
    const [infoTipVisible, setInfotipVisible] = useState(true);
    const [visibleLoading, setVisibleLoading] = useState(false);
    const [importResults, setImportResults] = useState();
    const refInfoTipBody = useRef(null);
    const clickSourceRef = useRef('');

    const clickAction = useCallback(
        (source) => {
            if (source) {
                clickSourceRef.current = source;
            }
            setInfotipVisible(false);
            if (loading) {
                setVisibleLoading(true);
            } else {
                const action = getOnClickAction({
                    importResults,
                    setImportResults,
                    data,
                    addNotification,
                    importInProgress,
                    setImportInProgress,
                    url,
                    talantixPromoUrl,
                });
                switch (clickSourceRef.current) {
                    case 'button':
                        trackEvent('import_button', 'click_import_button');
                        break;
                    case 'infotip':
                        talantixLinkContactsHideButtonClick({ hhtmSourceLabel: 'contacts_hide' });
                        if (data?.isAuthenticated) {
                            trackEvent('import_infotip_for_user', 'click_import');
                        } else {
                            trackEvent('import_infotip_for_non_user', 'click_go_to_talantix');
                        }
                        break;
                }
                action(resume.hash);
            }
        },
        [data, addNotification, importInProgress, importResults, loading, resume.hash, talantixPromoUrl, url]
    );

    useEffect(() => {
        if (!loading && visibleLoading) {
            clickAction();
            setVisibleLoading(false);
        }
    }, [clickAction, loading, visibleLoading]);

    const canImport = data?.canImport;

    useEffect(() => {
        if (canImport && showInfoTipPromo) {
            addNotification(talalantixResumeImportedPromo, {
                props: {
                    onClose: () => Notices.markAsViewed(INFOTIP_NAME_PROMO),
                },
            });
        }
    }, [canImport, addNotification, showInfoTipPromo]);

    const [setDelayedSeenEvent] = useAnalyticsEvents({
        loading,
        infotipActionName,
    });

    useEffect(() => {
        if (activatorRef.current) {
            talantixLinkContactsHideElementShown(activatorRef.current, { hhtmSourceLabel: 'contacts_hide' });
        }
    }, []);

    return (
        <Infotip
            name={INFOTIP_NAME}
            placement={InfoPlacement.BottomStart}
            onSeen={() =>
                refInfoTipBody.current &&
                talantixLinkContactsHideElementShown(refInfoTipBody.current, { hhtmSourceLabel: 'contacts_hide' })
            }
            onClose={() => setDelayedSeenEvent(true)}
            render={() => (
                <div ref={refInfoTipBody}>
                    <Text strong data-qa="resume-talantix-import-button__infotip-title">
                        {trls[ResumeTalantixButton.trls.infotipTitle]}
                    </Text>
                    <span data-qa="resume-talantix-import-button__infotip-text">
                        {trls[ResumeTalantixButton.trls.infotipContent]}
                    </span>
                    <Gap top>
                        <BlokoButton
                            data-qa="resume-talantix-import-button__infotip-button"
                            kind={ButtonKind.Inversed}
                            appearance={ButtonAppearance.Outlined}
                            onClick={() => {
                                clickAction('infotip');
                            }}
                            loading={loading ? <Loading scale={LoadingScale.Small} /> : undefined}
                        >
                            {data?.isAuthenticated
                                ? trls[ResumeTalantixButton.trls.infotipButtonCanImport]
                                : trls[ResumeTalantixButton.trls.infotipButtonToPromo]}
                        </BlokoButton>
                    </Gap>
                </div>
            )}
            theme={InfoTheme.Dark}
            show={infoTipVisible}
            activatorRef={activatorRef}
        >
            {isMagritte ? (
                <>
                    <Button
                        size="small"
                        mode="secondary"
                        icon={<TalantixScaleSmall initial={IconColor.Black} />}
                        onClick={() => {
                            resumeTalantixButtonClick({ resumeId: resume.id, vacancyId });
                            clickAction('button');
                        }}
                        aria-label="talantix-import"
                        data-qa="resume-talantix-import-button"
                        ref={activatorRef}
                    >
                        {null}
                    </Button>
                    <TooltipHover activatorRef={activatorRef} placement="top-center">
                        {trls[ResumeTalantixButton.trls.hoverTip]}
                    </TooltipHover>
                </>
            ) : (
                <HoverTip
                    host={isClient ? document.body : null}
                    layer={TipLayer.Overlay}
                    render={() => trls[ResumeTalantixButton.trls.hoverTip]}
                    activatorRef={activatorRef}
                >
                    <BlokoButton
                        data-qa="resume-talantix-import-button"
                        loading={
                            importInProgress || (visibleLoading && loading) ? (
                                <Loading scale={LoadingScale.Small} />
                            ) : undefined
                        }
                        kind={ButtonKind.IconOnly}
                        icon={<TalantixScaleSmall initial={IconColor.Gray50} />}
                        onClick={() => {
                            resumeTalantixButtonClick({ resumeId: resume.id, vacancyId });
                            clickAction('button');
                        }}
                        innerRef={activatorRef}
                    />
                </HoverTip>
            )}
        </Infotip>
    );
};

ResumeTalantixButton.trls = {
    hoverTip: 'talantix.resumebutton.hovertip',
    infotipContent: 'talantix.resumebutton.infotipContent',
    infotipTitle: 'talantix.resumebutton.infotipTitle',
    infotipButtonCanImport: 'talantix.resumebutton.infotipButtonCanImport',
    infotipButtonToPromo: 'talantix.resumebutton.infotipButtonToPromo',
};

export default translation(ResumeTalantixButton);
