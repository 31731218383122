import { FC } from 'react';

import { Avatar } from '@hh.ru/magritte-ui';

import { OnlineStatus, useOnlineStatus } from 'Utils/LastActivityTime';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';
import { Gender, HiddenFields } from 'src/models/resume/resumeCommon.types';
import { useResumeApplicantFullName } from 'src/pages/ResumeView/employerRedesign/utils';

import PhotoModal from 'src/pages/ResumeView/employerRedesign/components/ResumeMainInfo/PhotoModal';

const Photo: FC = () => {
    const isHidden = useSelector((state) => state.resume?.hiddenFields?.includes(HiddenFields.NamesAndPhoto));
    const gender = useSelector((state) => state.resume?.gender.value || Gender.Unknown);
    const photoUrl = useSelector((state) => (!isHidden ? state.resume?.photoUrls?.value?.[0]?.big : undefined));
    const lastActivityTime = useSelector((state) => state.resume?.lastActivityTime || '');
    const status = useOnlineStatus(lastActivityTime);
    const name = useResumeApplicantFullName();

    const [isVisibleModal, openModal, closeModal] = useOnOffState(false);

    return (
        <>
            <Avatar
                Element={photoUrl ? 'button' : 'div'}
                size={96}
                mode={photoUrl && !isHidden ? 'image' : 'placeholder'}
                image={photoUrl}
                fallbackMode="placeholder"
                placeholder={gender === Gender.Unknown ? 'undefined' : gender}
                aria-label="resume photo"
                onClick={openModal}
                online={status === OnlineStatus.Online}
            />
            {photoUrl && <PhotoModal isVisible={isVisibleModal} close={closeModal} imgUrl={photoUrl} title={name} />}
        </>
    );
};

export default translation(Photo);
