import { MouseEventHandler, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { OK } from 'http-status-codes';

import Analytics from '@hh.ru/analytics-js';

import { useNotification } from 'src/components/Notifications/Provider';
import getContactPhones from 'src/components/ResumeView/getContactPhones';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector, useSelectorNonNullable } from 'src/hooks/useSelector';

const useShowContacts = (setContactsVisibility: (value: boolean) => void): [MouseEventHandler, boolean] => {
    const { created, hash: resumeHash, id: resumeId, simHash } = useSelectorNonNullable(({ resume }) => resume);
    const vacancyId = useSelector(({ router }) => router.location.query?.vacancyId);
    const topicId = useSelector(({ router }) => router.location.query?.t);
    const dispatch = useDispatch();
    const { addNotification } = useNotification();

    const [loading, turnLoadingOn, turnLoadingOff] = useOnOffState(false);

    const showContacts: MouseEventHandler = useCallback(
        (event) => {
            turnLoadingOn();
            getContactPhones(
                { created, resumeHash, simHash, event, goal: 'Contacts_Phone', topicId },
                addNotification
            )(dispatch)
                .then(({ status }) => {
                    if (status === OK) {
                        Analytics.sendHHEvent('resumeShowContacts', {
                            goal: 'ShowContacts',
                            resumeId,
                            vacancyId,
                        });
                        setContactsVisibility(true);
                    } else {
                        turnLoadingOff();
                    }
                })
                .catch(console.error);
        },
        [
            addNotification,
            created,
            dispatch,
            resumeHash,
            resumeId,
            setContactsVisibility,
            simHash,
            topicId,
            turnLoadingOff,
            turnLoadingOn,
            vacancyId,
        ]
    );

    return useMemo(() => [showContacts, loading], [showContacts, loading]);
};

export default useShowContacts;
