import { Dispatch, SetStateAction, useState } from 'react';

import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import { getVerifiedSkillCategory } from 'src/components/OnbordingSkillsToolTip/utills';

const SKILL_VERIFICATION_TIP = 'skillVerificationTip';

interface UseVisibleToolTipProps {
    skillCategory: string;
    skillName: string;
}
interface UseVisibleToolTipResponse {
    isTipVisible: boolean;
    setIsTipVisible: Dispatch<SetStateAction<boolean>>;
    skillVerificationTip: string;
}

const useVisibleOnboardingSkillsToolTip = ({
    skillName,
    skillCategory,
}: UseVisibleToolTipProps): UseVisibleToolTipResponse => {
    const isEmployer = useSelector((state) => state.userType === UserType.Employer);
    const verifiedSkills = useSelector((state) => state.resume?.verifiedSkills);
    const verifiedSkillCategory = verifiedSkills && getVerifiedSkillCategory(verifiedSkills);
    const skillToShow = verifiedSkills?.find((skill) => skill.category === skillCategory);
    const infoTip = useSelector(({ infoTip }) => infoTip);
    const [isTipVisible, setIsTipVisible] = useState(
        isEmployer &&
            infoTip.name === SKILL_VERIFICATION_TIP &&
            verifiedSkillCategory === skillCategory &&
            skillToShow?.name === skillName
    );

    return { isTipVisible, setIsTipVisible, skillVerificationTip: SKILL_VERIFICATION_TIP };
};

export default useVisibleOnboardingSkillsToolTip;
