import { connect } from 'react-redux';

import Column from 'bloko/blocks/column';

import Markup from 'src/components/Markup';
import translation from 'src/components/translation';
import useGetMColumnSize from 'src/pages/ResumeView/hooks/useGetMColumnSize';

import ResumeBlock from 'src/pages/ResumeView/components/View/ResumeBlock';
import ResumeEditLink from 'src/pages/ResumeView/components/View/ResumeEditLink';

const ResumeSkills = ({ skills, trls }) => {
    const { mMain } = useGetMColumnSize();
    if (!skills) {
        return null;
    }

    return (
        <ResumeBlock
            title={trls[ResumeSkills.trls.title]}
            EditLink={<ResumeEditLink block="experience" field="skills" data-qa="resume-block-skills-edit" />}
            data-qa="resume-block-skills"
        >
            <Column xs="4" s="8" m={mMain} l="12">
                <div className="resume-block-container" data-qa="resume-block-skills-content">
                    <Markup>{skills}</Markup>
                </div>
            </Column>
        </ResumeBlock>
    );
};

ResumeSkills.trls = {
    title: 'rb.title.skills',
};

export default connect((state) => ({
    skills: state.resume?.skills.value,
}))(translation(ResumeSkills));
